/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// let injectedScript = false
// const AZ_INSTRUMENTATION = process.env.AZ_INSTRUMENTATION_KEY;

import React from 'react';
import { ResetFormProvider } from './src/context/ResetFormContext';

export const wrapPageElement = ({ element, props }) => {
  return <ResetFormProvider {...props}>{element}</ResetFormProvider>;
};


const scrollToElement = require('scroll-to-element')


// export const onInitialClientRender = () => {
//     function addJS(jsCode) {
//         var s = document.createElement(`script`);
//         s.type = `text/javascript`;
//         s.innerText = jsCode;
//         document.getElementsByTagName(`head`)[0].appendChild(s);
//     }
//     if (!injectedScript) {
//         addJS(`
//     var appInsights = window.appInsights || function (a) {
//         function b(a) { c[a] = function () { var b = arguments; c.queue.push(function () { c[a].apply(c, b) }) } } var c = { config: a }, d = document, e = window; setTimeout(function () { var b = d.createElement("script"); b.src = a.url || "https://az416426.vo.msecnd.net/scripts/a/ai.0.js", d.getElementsByTagName("script")[0].parentNode.appendChild(b) }); try { c.cookie = d.cookie } catch (a) { } c.queue = []; for (var f = ["Event", "Exception", "Metric", "PageView", "Trace", "Dependency"]; f.length;)b("track" + f.pop()); if (b("setAuthenticatedUserContext"), b("clearAuthenticatedUserContext"), b("startTrackEvent"), b("stopTrackEvent"), b("startTrackPage"), b("stopTrackPage"), b("flush"), !a.disableExceptionTracking) { f = "onerror", b("_" + f); var g = e[f]; e[f] = function (a, b, d, e, h) { var i = g && g(a, b, d, e, h); return !0 !== i && c["_" + f](a, b, d, e, h), i } } return c
//     }({
//         instrumentationKey: "${AZ_INSTRUMENTATION}"
//     });

//     window.appInsights = appInsights, appInsights.queue && 0 === appInsights.queue.length && appInsights.trackPageView();
//       `);
//         injectedScript = true;
//     }
// }

// export const onRouteUpdate = ({ location, prevLocation }) => {

//     setTimeout(()=>{
//         window.appInsights.trackPageView();
//     }, 100);
//     // checkHash(location);
// }

const checkHash = location => {
  let { hash } = location
  if (hash) {
    scrollToElement(hash, {
      duration: 1000,
    })
  }
}