import React, { createContext, useState, useContext, useEffect } from 'react';

const ResetFormContext = createContext();

export const ResetFormProvider = ({ children, location }) => {
  const [shouldResetForm, setShouldResetForm] = useState(false);

  useEffect(() => {
    if (location.pathname === '/patients/caregiver-signup/') {
      setShouldResetForm(true);
    } else {
      setShouldResetForm(false);
    }
  }, [location]);

  return (
    <ResetFormContext.Provider value={{ shouldResetForm, setShouldResetForm }}>
      {children}
    </ResetFormContext.Provider>
  );
};

export const useResetForm = () => useContext(ResetFormContext);